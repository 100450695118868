@import "~primeng/resources/primeng.min.css";
@import "~primeng/resources/themes/saga-orange/theme.css";
@import "~primeicons/primeicons.css";
@import "~primeflex/primeflex.css";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

html,
body {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
